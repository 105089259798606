import React from "react"

// gatsby libraries
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Slide from "@material-ui/core/Slide"

// @material-ui/icons
import Close from "@material-ui/icons/Close"
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft"
import InfoIcon from "@material-ui/icons/Info"

// core components
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import Danger from "components/Typography/Danger.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Success from "components/Typography/Success.jsx"

// page images
import chiliBorderIcon from "assets/img/icons/chili-pepper-2.svg"

// page styles
import blogPostsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageSections/blogPostsStyle.jsx"
const useStyles = makeStyles(blogPostsStyle)

// page modal functions
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

export default function TipsTab() {
  const [tipsModal1, setTipsModal1] = React.useState(false)
  const [tipsModal2, setTipsModal2] = React.useState(false)
  const [scroll1, setScroll1] = React.useState("body")
  const [scroll2, setScroll2] = React.useState("body")
  const { chilimaniaAerialBlogImage, stageBlogImage } = useStaticQuery(graphql`
    query getTipsTabImages {
      chilimaniaAerialBlogImage: file(
        relativePath: { eq: "blog/chilimania-aerial-blog.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      stageBlogImage: file(relativePath: { eq: "blog/stage-blog.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  const handleClickOpen1 = () => {
    setTipsModal1(true)
    setScroll1("body")
  }

  const handleClickOpen2 = () => {
    setTipsModal2(true)
    setScroll2("body")
  }

  const classes = useStyles()
  return (
    <>
      <Card plain blog className={classes.card}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CardHeader image plain>
              <GatsbyImage
                image={
                  chilimaniaAerialBlogImage.childImageSharp.gatsbyImageData
                }
                className={classes.blogPostsImage}
                alt="Tips Blog Post 1"
              />
            </CardHeader>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <Success>
              <h6 className={classes.cardCategory}>
                <InfoIcon className={classes.cardIcon} />
                Tips
              </h6>
            </Success>
            <h3 className={classes.cardTitle}>Tips for Chilimania attendees</h3>
            <p className={classes.description}>
              View information on Chilimania admission, location, directions,
              parking, and more.
            </p>
            <Button
              round
              href=""
              color="primary"
              className={classes.blogPostsButton}
              onClick={handleClickOpen1}
            >
              <FormatAlignLeft className={classes.icons} /> Read article
            </Button>
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal + " " + classes.modalLarge,
              }}
              open={tipsModal1}
              scroll={scroll1}
              TransitionComponent={Transition}
              onClose={() => setTipsModal1(false)}
              aria-labelledby="tips-modal-1-slide-title"
              aria-describedby="tips-modal-1-slide-description"
            >
              <DialogTitle
                id="tips-modal-1-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  simple
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={() => setTipsModal1(false)}
                >
                  <Close className={classes.modalClose} />
                </Button>
                <h6 className={classes.modalCardCategory}>
                  <InfoIcon className={classes.cardIcon} />
                  TIPS
                </h6>
              </DialogTitle>
              <DialogContent
                id="tips-modal-1-slide-description"
                className={classes.modalBody}
              >
                <GatsbyImage
                  image={stageBlogImage.childImageSharp.gatsbyImageData}
                  className={classes.modalBlogPostsImage}
                  alt="Tips Blog Post 1"
                />
                <h3 className={classes.modalBlogPostsTitle}>
                  Attending Chilimania? Here's Some Tips &amp; Reminders:
                </h3>
                <hr
                  style={{ borderImage: `url(${chiliBorderIcon})` }}
                  className={classes.chiliBorder}
                />
                <div className={classes.modalBlogPostsDescription}>
                  <ul className={classes.modalBlogPostsList}>
                    <li>
                      There is abundant street parking throughout downtown
                      Edgerton. There are a few paid lots, not associated with
                      Chilimania. Please be respectful of our downtown and our
                      neighbors when arriving at and leaving the festival.
                    </li>
                    <li>
                      We have been made aware that there will be both Uber and
                      Lyft drivers in the area to take people to and from
                      downtown Edgerton. Please download those apps for driver
                      availability and to arrange rides.
                    </li>
                    <li>
                      Tickets are $10 each for adults; children 12 &amp; under
                      admitted free with a paid adult. cash or card accepted; a
                      per-ticket processing fee applies to all debit/credit card
                      orders.
                    </li>
                    <li>
                      The Chilimania gates open at 10:30AM and close at
                      midnight. The main gate is at the corner of Fulton and
                      North Henry streets, but there are several entrances
                      located along North Henry.
                    </li>
                    <li>
                      There are various events taking place all day long. Please
                      view our master schedule, cook-off schedule, and music
                      lineup to make sure you don’t miss anything!
                    </li>
                    <li>
                      All events, except for the Best Bloody Mary contest, take
                      place on North Henry Street. The Bloody Mary contest takes
                      place at 10AM at Two Brothers Downtown Pub and Grill, 116
                      W. Fulton St.
                    </li>
                    <li>
                      If you are cooking chili, be sure to have all of your
                      cooks on-site by 10AM, or they will need to pay the
                      admission fee if arriving later.
                    </li>
                    <li>
                      The public chili tasting begins at 3PM. Arrive early to
                      avoid lines at the gate. Chili goes fast, sometimes in as
                      little as an hour.
                    </li>
                    <li>
                      There is very little seating provided in the stage area,
                      so bring a lawn chair if you don’t want to stand.
                    </li>
                    <li>
                      No carry-in food or beverage. We have a variety of vendors
                      serving all weekend long.
                    </li>
                    <li>
                      Two ATMs will be available on the grounds. A withdrawal
                      fee will apply.
                    </li>
                    <li>
                      Cellular service in downtown Edgerton can be spotty,
                      depending on your carrier. No public wi-fi available.
                    </li>
                    <li>
                      No weapons allowed. Please leave them at home or locked
                      securely in your vehicle.
                    </li>
                    <li>
                      We strongly suggest leaving your pets at home. The crowds,
                      noise, food/drink, and other factors make it very tough on
                      your four-legged friend!
                    </li>
                  </ul>
                </div>
              </DialogContent>
              <DialogActions className={classes.modalBlogPostsFooter}>
                <Button
                  onClick={() => setTipsModal1(false)}
                  color="danger"
                  simple
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </Card>
      <div className={classes.tabSpacer}></div>
      <Card plain blog className={classes.card}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <CardHeader image plain>
              <GatsbyImage
                image={stageBlogImage.childImageSharp.gatsbyImageData}
                className={classes.blogPostsImage}
                alt="Tips Blog Post 2"
              />
            </CardHeader>
          </GridItem>
          <GridItem xs={12} sm={12} md={8}>
            <Danger>
              <h6 className={classes.cardCategory}>
                <InfoIcon className={classes.cardIcon} />
                Tips
              </h6>
            </Danger>
            <h3 className={classes.cardTitle}>
              Tips for Country Edge ticket holders
            </h3>
            <p className={classes.description}>
              View information on Country Edge admission, location, directions,
              parking, and more.
            </p>
            <Button
              round
              href=""
              color="primary"
              className={classes.blogPostsButton}
              onClick={handleClickOpen2}
            >
              <FormatAlignLeft className={classes.icons} /> Read article
            </Button>
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal + " " + classes.modalLarge,
              }}
              open={tipsModal2}
              scroll={scroll2}
              TransitionComponent={Transition}
              onClose={() => setTipsModal1(false)}
              aria-labelledby="tips-modal-2-slide-title"
              aria-describedby="tips-modal-2-slide-description"
            >
              <DialogTitle
                id="tips-modal-2-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <Button
                  simple
                  className={classes.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={() => setTipsModal1(false)}
                >
                  <Close className={classes.modalClose} />
                </Button>
                <h6 className={classes.modalCardCategory}>
                  <InfoIcon className={classes.cardIcon} />
                  Tips
                </h6>
              </DialogTitle>
              <DialogContent
                id="tips-modal-2-slide-description"
                className={classes.modalBody}
              >
                <GatsbyImage
                  image={
                    chilimaniaAerialBlogImage.childImageSharp.gatsbyImageData
                  }
                  className={classes.modalBlogPostsImage}
                  alt="Tips Blog Post 2"
                />
                <h3 className={classes.modalBlogPostsTitle}>
                  Ready for Country Edge? Here's Some Tips &amp; Reminders
                </h3>
                <hr
                  style={{ borderImage: `url(${chiliBorderIcon})` }}
                  className={classes.chiliBorder}
                />
                <div className={classes.modalBlogPostsDescription}>
                  <ul className={classes.modalBlogPostsList}>
                    <li>
                      If you do not already have tickets, you can save $5 each
                      if you purchase before 11:59PM on Thursday, Sept. 10.
                      Click here to purchase tickets:
                      <a
                        className={classes.externalLink}
                        href="http://countryedgerton.com/"
                      >
                        http://countryedgerton.com/
                      </a>
                    </li>
                    <li>
                      The easiest way to enter is through the main gate, located
                      at the intersection of Fulton and North Henry streets.
                      There also will be gates located in the alley behind Two
                      Brothers Downtown Pub &amp; Grill, as well as on Canal
                      Street, near our beer trailer.
                    </li>
                    <li>
                      Arrive early to avoid lines. Gates open to
                      general-admission ticket holders at 6PM on Friday, Sept.
                      6, with music beginning at 7PM.
                    </li>
                    <li>
                      If you purchased VIP tickets, you may enter through any
                      gate beginning at 5PM. From there you will be directed to
                      the VIP area.
                    </li>
                    <li>
                      Bring your tickets, either printed or on your phone. We
                      need to scan the QR code before you can enter.
                    </li>
                    <li>
                      If you bought advance tickets, you will be directed to a
                      line to have them scanned.
                    </li>
                    <li>
                      If you need to buy tickets, there will be a separate line.
                      $25 each, cash or card only. A $2 per ticket processing
                      fee applies to all debit/credit card orders.
                    </li>
                    <li>
                      There is NOT any seating provided in the general-admission
                      area, so bring a lawn chair if you don’t want to stand all
                      night. Bleacher seating is provided in the VIP area, so
                      please do not bring chairs if you have VIP tickets.
                    </li>
                    <li>
                      No carry-in food or beverage. We have a variety of vendors
                      serving all weekend long.
                    </li>
                    <li>
                      Two ATMs will be available on the grounds. A withdrawal
                      fee will apply.
                    </li>
                    <li>
                      Cellular service in downtown Edgerton can be spotty,
                      depending on your carrier. No public wi-fi available.
                    </li>
                    <li>
                      No weapons allowed. Please leave them at home or locked
                      securely in your vehicle.
                    </li>
                    <li>
                      There is abundant street parking throughout downtown
                      Edgerton. There are a few paid lots, not associated with
                      Chilimania. Please be respectful of our downtown and our
                      neighbors when arriving at and leaving the festival.
                    </li>
                    <li>
                      We have been made aware that there will be both Uber and
                      Lyft drivers in the area to take people to and from
                      downtown Edgerton. Please download those apps for driver
                      availability and to arrange rides.
                    </li>
                    <li>
                      We can’t wait to party with you all throughout the
                      weekend!
                    </li>
                  </ul>
                </div>
              </DialogContent>
              <DialogActions className={classes.modalBlogPostsFooter}>
                <Button
                  onClick={() => setTipsModal2(false)}
                  color="danger"
                  simple
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </GridItem>
        </GridContainer>
      </Card>
      <div className={classes.tabSpacer}></div>
    </>
  )
}
